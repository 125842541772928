import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

import $ from 'jquery';
import axios from 'axios';
import ReactFullpage from '@fullpage/react-fullpage';
// javascript plugin used to create scrollbars on windows
import { motion } from 'framer-motion';
import Aos from 'aos';
import 'aos/dist/aos.css';
import ReactTextTransition, { presets } from 'react-text-transition';
import validate from 'validate.js';
import Helmet from 'react-helmet';

// reactstrap components
import {
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

// core components
import Navibar from 'components/Navbars/Navibar.js';
import Footer from 'components/Footer/Footer.js';
import Aboutimg from 'assets/img/hero_desktop_contact.jpg';

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'required' },
  },

  email: {
    presence: { allowEmpty: false, message: 'required' },
    email: true,
    length: {
      maximum: 100,
    },
  },

  phone: {
    presence: { allowEmpty: false, message: 'required' },
    length: {
      maximum: 12,
      minimum: 10,
    },
    format: {
      pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4,5})$/,
      message: 'Phone Number Format Incorrect.',
    },
  },
  message: {
    presence: { allowEmpty: false, message: 'required' },
  },
};

export default function Contact() {
  const [nameFocus, setNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [phoneFocus, setPhoneFocus] = React.useState(false);
  const [messageFocus, setMessageFocus] = React.useState(false);
  const [visible, setVisible] = React.useState(true);
  const [eachEntry, setEachEntry] = useState({});
  const [successModal, setSuccessModal] = React.useState(false);

  const [newText, setNewText] = useState(0);
  const [arrow, setArrow] = useState('');

  const text_1 = ['We', 'Are'];
  const text_2 = ['would', 'you'];
  const text_3 = ['love', 'ready'];
  const text_4 = ['hear', 'talk'];
  const text_5 = ['from', 'impact?'];
  const text_6 = ['you', ''];

  const initialState = {
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  };
  const [formState, setFormState] = useState(initialState);

  const handleNameChange = (e) => {
    e.persist();
    if (e.target.value !== '') {
      setNameFocus(true);
    } else {
      setNameFocus(false);
    }
    setFormState((formState) => ({
      ...formState,
      values: { ...formState.values, [e.target.name]: e.target.value },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };
  const handleEmailChange = (e) => {
    e.persist();
    if (e.target.value !== '') {
      setEmailFocus(true);
    } else {
      setEmailFocus(false);
    }
    setFormState((formState) => ({
      ...formState,
      values: { ...formState.values, [e.target.name]: e.target.value },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };
  const handlePhoneChange = (e) => {
    e.persist();
    if (e.target.value !== '') {
      setPhoneFocus(true);
    } else {
      setPhoneFocus(false);
    }
    setFormState((formState) => ({
      ...formState,
      values: { ...formState.values, [e.target.name]: e.target.value },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };
  const handleMessageChange = (e) => {
    if (e.target.value !== '') {
      setMessageFocus(true);
    } else {
      setMessageFocus(false);
    }
    setFormState((formState) => ({
      ...formState,
      values: { ...formState.values, [e.target.name]: e.target.value },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };

  const pageTwo = () => {
    setVisible(false);
    setNewText(1);
    setArrow('exit');
  };

  const pageOne = () => {
    setVisible(true);
    setNewText(0);
    setArrow('');
  };

  React.useEffect(() => {
    Aos.init({ duration: 1500, once: true });
    $('body').on('click', 'a.corner_btn', function () {
      setTimeout(function () {
        $('.logo_img').css('display', 'none');
        $('.navbar .navbar-toggler-bar').css('background', '#000');
      }, 700);
      pageTwo();
      setTimeout(function () {
        $('.contact_left.header_keywords').addClass('black_text');
      }, 400);
    });
    console.log($('.contact_form').pageYOffset);
    /* Validation */
    const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleLoaded = (_) => {
    window.grecaptcha.ready((_) => {
      window.grecaptcha
        .execute('_reCAPTCHA_site_key_', { action: 'homepage' })
        .then((token) => {
          // ...
        });
    });
  };

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement('script');
    script.src =
      'https://www.google.com/recaptcha/api.js?render=6LdVNXEgAAAAADRwvmIp2l24Tn57F0p-IDwDStIQ';
    script.addEventListener('load', handleLoaded);
    document.body.appendChild(script);
  }, []);

  /* Submit Form */
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      data: {
        name: formState.values.name,
        email: formState.values.email,
        phone_number: formState.values.phone,
        message: formState.values.message,
      },
    };
    axios({
      method: 'post',
      url: 'https://admin.digitalsymphony.it/api/contacts',
      data: data,
      headers: { 'Content-Type': 'application/json' },
    })
      .then(function (response) {
        //handle success
        console.log(response);
        console.log('success');
        setSuccessModal(true);
        setTimeout(function () {
          setSuccessModal(true);
        }, 3000);
        setTimeout(function () {
          window.location.href = 'https://digitalsymphony.it/';
        }, 5000);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };
  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <title>Contact | Digital Symphony</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />

        <meta property="og:title" content="Contact | Digital Symphony" />
        <meta property="og:image" content="https://digitalsymphony.it/logo/share.png" /> 
        <meta property="og:description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />  
        <meta property="og:url" content="https://digitalsymphony.it/" /> 

        <meta name="title" content="Contact | Digital Symphony" />
        <meta name="description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />
        <meta name="keywords" content="Kuala Lumpur, Malaysia, Digital Symphony, Digital Agency, performance" />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
        <meta name="author" content="Digital Symphony" />
      </Helmet>
      <Navibar />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={`wrapper contact_wrapper`}
        style={{ backgroundImage: `url(${Aboutimg})` }}
      >
        <h5 className="contact_left header_keywords is-sticky hide_in_mobile">
          <ReactTextTransition
            text={text_1[newText]}
            springConfig={presets.gentle}
            style={{ margin: '0 4px' }}
            delay={350}
            inline
          />
          <br />
          <ReactTextTransition
            text={text_2[newText]}
            springConfig={presets.gentle}
            style={{ margin: '0 4px' }}
            delay={300}
            inline
          />
          <ReactTextTransition
            text={text_3[newText]}
            springConfig={presets.gentle}
            style={{ margin: '0 4px' }}
            delay={300}
            inline
          />
          <br />
          <span>to </span>
          <ReactTextTransition
            text={text_4[newText]}
            springConfig={presets.gentle}
            style={{ margin: '0 4px' }}
            delay={200}
            inline
          />
          <ReactTextTransition
            text={text_5[newText]}
            springConfig={presets.gentle}
            style={{ margin: '0 4px' }}
            delay={200}
            inline
          />
          <br />
          <FontAwesomeIcon
            size="1x"
            icon={faLongArrowAltRight}
            className={'exit_left ' + arrow}
          />

          <ReactTextTransition
            text={text_6[newText]}
            springConfig={presets.gentle}
            style={{ margin: '0 4px' }}
            delay={100}
            inline
          />
        </h5>
        <ReactFullpage
          //fullpage options
          licenseKey={'YOUR_KEY_HERE'}
          scrollingSpeed={1000} /* Options here */
          scrollOverflow={true}
          fitToSection={true}
          scrollOverflowMacStyle={true}
          scrollBar={false}
          touchSensitivity={75}
          responsiveWidth={575}
          onLeave={(origin, destination, direction) => {
            if (window.innerWidth > 575) {
              if (direction == 'down') {
                setTimeout(function () {
                  $('.navbar .navbar-toggler-bar').css('background', '#000');
                }, 700);
                pageTwo();
                setTimeout(function () {
                  $('.contact_left.header_keywords').addClass('black_text');
                }, 400);
              } else {
                $('.navbar .navbar-toggler-bar').css('background', '#fff');
                setTimeout(function () {
                  $('.contact_left.header_keywords').removeClass('black_text');
                }, 200);
                pageOne();
              }
            }
          }}
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                <div className="section position-relative contact_one">
                  <div
                    className="page-header contact_first"
                    style={{ backgroundColor: 'transparent' }}
                  >
                    <Container
                      fluid={true}
                      className="align-items-center contact_container"
                    >
                      <Row>
                        <Col md="5" className="">
                          <h5
                            className="contact_left header_keywords show_in_mobile"
                            data-aos="fade-right"
                          >
                            We would love to hear from <br />
                            <FontAwesomeIcon
                              size="1x"
                              icon={faLongArrowAltRight}
                              data-aos="fade-right"
                            />{' '}
                            you.
                          </h5>
                        </Col>
                        <Col
                          md="7"
                          className="d-flex align-items-center flex-wrap pl-0 pr-0"
                        >
                          <div className="d-flex justify-content-between right_content">
                            <Col md="6" sm="6" xs="12" className="pl-0 pr-0">
                              <p
                                className="subtitle white_text"
                                style={{ maxWidth: '230px', lineHeight: '1.6' }}
                                data-aos="fade-up"
                              >
                                Feel free to reach out if you want to
                                collaborate with us, or simply have a chat.
                              </p>
                            </Col>
                            <Col md="6" sm="6" xs="12" className="pl-0 pr-0">
                              <a
                                className="d-flex align-items-center justify-content-end mail_link text-right mb-1"
                                href="mailto:barista@digitalsymphony.it"
                                data-aos="fade-left"
                              >
                                <i
                                  className="icon-email"
                                  style={{
                                    marginRight: '10px',
                                    fontSize: '14px',
                                  }}
                                />
                                &nbsp;barista@digitalsymphony.it (MY)
                              </a>
                              <a
                                className="d-flex align-items-center justify-content-end mail_link text-right"
                                href="tel:60378596573"
                                data-aos="fade-left"
                              >
                                <i
                                  className="icon-phone-reverse"
                                  style={{
                                    marginLeft: '5px',
                                    marginRight: '10px',
                                    fontSize: '18px',
                                  }}
                                />
                                &nbsp;+0378596573
                              </a>
                              {/* <a
                                className="d-flex align-items-center justify-content-end mail_link text-right mb-1"
                                href="mailto:chrix.yong@digitalsymphony.sg"
                                data-aos="fade-left"
                              >
                                <i
                                  className="icon-email"
                                  style={{
                                    marginRight: '10px',
                                    fontSize: '14px',
                                  }}
                                />
                                &nbsp;chrix.yong@digitalsymphony.sg (SG)
                              </a>
                              <a
                                className="d-flex align-items-center justify-content-end mail_link text-right"
                                href="tel:6597497417"
                                data-aos="fade-left"
                              >
                                <i
                                  className="icon-phone-reverse"
                                  style={{
                                    marginLeft: '5px',
                                    marginRight: '10px',
                                    fontSize: '18px',
                                  }}
                                />
                                &nbsp;+6597497417
                              </a> */}
                            </Col>
                          </div>
                          <div className="d-flex justify-content-between right_content">
                            <Col md="6" sm="6" xs="12" className="pl-0 pr-0">
                              <p
                                className="subtitle white_text"
                                style={{ maxWidth: '230px', lineHeight: '1.6' }}
                                data-aos="fade-up"
                              >
                                Unit C-3-7 & C-3-8, Block C, Pacific Place Commercial
                                Centre,
                                <br />
                                Jalan PJU 1A/4, Ara Damansara, 47301 Petaling
                                Jaya.
                                <br />
                                Selangor, Malaysia.
                              </p>
                            </Col>
                            <Col
                              md="6"
                              sm="6"
                              xs="12"
                              className="pl-0 pr-0 text-right"
                            >
                              <a
                                href="https://g.page/digitalsymp?share"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="apply_btn"
                                data-aos="fade-left"
                              >
                                VIEW ON GOOGLE MAPS{' '}
                                <i className="icon-right-arrow"></i>
                              </a>
                            </Col>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    <a
                      href="#"
                      onClick={() => {
                        pageTwo();
                        fullpageApi.moveSectionDown();
                      }}
                      className="corner_btn"
                    >
                      <i className="icon-right-arrow"></i>
                    </a>
                  </div>
                </div>
                <div
                  className="section contact_form"
                  style={{ backgroundColor: 'white' }}
                >
                  <Container
                    fluid={true}
                    className="align-items-center contact_container"
                  >
                    <Row>
                      <Col md="5" className="hide_in_mobile"></Col>
                      <Col md="7" className="">
                        <h1 className="text-left section_title">
                          Let’s work on your business goals
                        </h1>
                        <p className="subtitle text-left">
                          Feel free to reach out if you want to collaborate with
                          us, or simply have a chat.
                        </p>

                        <Form
                          role="form"
                          id="float-label"
                          onSubmit={(e) => handleSubmit(e)}
                          ref="form"
                        >
                          <FormGroup className="mb-3">
                            <label
                              className={nameFocus ? 'Active' : ''}
                              htmlFor="name"
                            >
                              NAME*
                            </label>
                            <Input
                              type="text"
                              name="name"
                              value={formState.values.name || ''}
                              onChange={handleNameChange}
                            />
                            {hasError('name') ? (
                              <div className="error_msg">
                                Error : {formState.errors.name[0]}{' '}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <label
                              className={emailFocus ? 'Active' : ''}
                              htmlFor="email"
                            >
                              EMAIL*
                            </label>
                            <Input
                              type="email"
                              name="email"
                              onChange={handleEmailChange}
                              value={formState.values.email || ''}
                            />
                            {hasError('email') ? (
                              <div className="error_msg">
                                Error : {formState.errors.email[0]}{' '}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <label
                              className={phoneFocus ? 'Active' : ''}
                              htmlFor="phone"
                            >
                              PHONE NUMBER*
                            </label>
                            <Input
                              type="number"
                              name="phone"
                              onChange={handlePhoneChange}
                              onKeyDown={(evt) =>
                                ['e', 'E', '+', '-', '.'].includes(evt.key) &&
                                evt.preventDefault()
                              }
                            />
                            {hasError('phone') ? (
                              <div className="error_msg">
                                Error : {formState.errors.phone[0]}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <label
                              className={messageFocus ? 'Active' : ''}
                              htmlFor="message"
                            >
                              MESSAGE*
                            </label>
                            <textarea
                              name="message"
                              id=""
                              rows="5"
                              onChange={handleMessageChange}
                            ></textarea>
                          </FormGroup>
                          <button
                            className="submit_btn"
                            type="submit"
                            disabled={formState.isValid ? false : true}
                          >
                            SUBMIT <i className="icon-right-arrow"></i>
                          </button>
                        </Form>
                      </Col>
                    </Row>
                  </Container>
                  <Footer />
                  <div
                    className="g-recaptcha"
                    data-sitekey="6LdVNXEgAAAAADRwvmIp2l24Tn57F0p-IDwDStIQ"
                    data-size="invisible"
                  ></div>
                </div>
              </ReactFullpage.Wrapper>
            );
          }}
        />
        <Modal isOpen={successModal}>
          <ModalBody>
            <img
              src="coffee-cup.gif"
              alt="success icon"
              className="coffee_icon"
            />
            <p
              className="subtitle text-center"
              style={{ marginBottom: '0', lineHeight: '1' }}
            >
              Thanks for reaching us.
            </p>
            <p className="subtitle text-center">
              We will get back to you as soon as possible.
            </p>
            <small
              className="subtitle text-center"
              style={{
                fontSize: '11px',
                margin: 'auto',
                textAlign: 'center',
                display: 'block',
              }}
            >
              Redirecting back to homepage...
            </small>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </motion.div>
    </>
  );
}

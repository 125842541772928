import React from 'react';
import { Link } from 'react-router-dom';
// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from 'reactstrap';

export default function Footer() {
  return (
    <footer className="footer">
      <Container fluid={true} className="px-5">
        <Row>
          <Col md="4">
            <h1 className="title">
              Lets talk innovation. <br /> Chat us up
            </h1>
            <a
              className="d-flex align-items-center mail_link"
              href="mailto:barista@digitalsymphony.it"
              style={{ color: 'white' }}
            >
              <i
                className="icon-email"
                style={{ marginRight: '10px', fontSize: '14px' }}
              />
              &nbsp; barista@digitalsymphony.it (MY)
            </a>
            <a
              className="d-flex align-items-center mail_link mb-4"
              href="tel:60378596573"
            >
              <i
                className="icon-phone-reverse"
                style={{
                  marginLeft: '5px',
                  marginRight: '10px',
                  fontSize: '18px',
                }}
              />
              &nbsp; +0378596573
            </a>
            {/* <a
              className="d-flex align-items-center mail_link"
              href="mailto:chrix.yong@digitalsymphony.sg"
              style={{ color: 'white' }}
            >
              <i
                className="icon-email"
                style={{ marginRight: '10px', fontSize: '14px' }}
              />
              &nbsp; chrix.yong@digitalsymphony.sg (SG)
            </a>
            <a
              className="d-flex align-items-center mail_link"
              href="tel:6597497417"
            >
              <i
                className="icon-phone-reverse"
                style={{
                  marginLeft: '5px',
                  marginRight: '10px',
                  fontSize: '18px',
                }}
              />
              &nbsp; +6597497417
            </a> */}
          </Col>
          <Col md="3" sm="6">
            <p className="text-uppercase footer_label">Quick Links</p>
            <Nav>
              <NavItem>
                <NavLink to="/about" tag={Link}>
                  About Us
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/journal" tag={Link}>
                  Journal
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/work" tag={Link}>
                  Work
                </NavLink>
              </NavItem>
              <NavItem>
                <a href="/contact-us" className="nav-link" tag={Link}>
                  Contact us
                </a>
              </NavItem>
              <NavItem>
                <NavLink to="/service" tag={Link}>
                  Services
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/career" tag={Link}>
                  Career
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col md="2" sm="3" className="social_col">
            <p className="text-uppercase footer_label">FOLLOW US</p>
            <Nav>
              <NavItem>
                <NavLink href="https://www.facebook.com/digitalsymp/">
                  <i className="fab fa-facebook" />
                  Facebook
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://www.instagram.com/digitalsymp/">
                  <i className="fab fa-instagram" />
                  Instagram
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://www.linkedin.com/company/3926676/admin/">
                  <i className="fab fa-linkedin-in" />
                  Linkedin
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col md="3" sm="3">
            <p className="text-uppercase footer_label">Proudly</p>
            <img
              src="/about/meta-badge2.png"
              alt="FB Partner"
              className="facebook_proud"
            />
            <img
              src="/about/GPartner2.png"
              alt="Google Partner"
              className="google_proud"
            />
          </Col>
          <Col md="12">
            <p className="copyright">
              ©2025 DS Services Sdn.Bhd. [Registration No. 201701001509
              (1215659-P)]. All Rights Reserved. | <a className="text-reset text-decoration-none" href="https://dswebsite-assets.s3.ap-southeast-1.amazonaws.com/DS_Website_Privacy_Policy_8fe03aa52b.pdf" target="_blank">Privacy Policy</a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

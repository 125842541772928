import React from 'react';
// javascript plugin used to create scrollbars on windows
import Aos from 'aos';
import 'aos/dist/aos.css';
import 'assets/css/about.css';
import { Link } from 'react-router-dom';
import { Gradient } from 'whatamesh';
// reactstrap components
import { Container, Row, Col } from 'reactstrap';
import Helmet from 'react-helmet';

// core components
import Navibar from 'components/Navbars/Navibar.js';
import Footer from 'components/Footer/Footer.js';
import OfficeOwl from 'components/Carousel/office-life.js';
import MyTeam from 'components/Carousel/my-team.js';
import AboutUs from 'components/Carousel/about-us.js';

export default function About() {
  const gradient = new Gradient();

  React.useEffect(() => {
    Aos.init({ duration: 1500, once: true });
    gradient.initGradient('#gradient-canvas');
  }, []);
  return (
    <>
    <Helmet>
        <meta charset="utf-8" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <title>About | Digital Symphony</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />

        <meta property="og:title" content="About | Digital Symphony" />
        <meta property="og:image" content="https://digitalsymphony.it/logo/share.png" /> 
        <meta property="og:description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />  
        <meta property="og:url" content="https://digitalsymphony.it/" /> 

        <meta name="title" content="About | Digital Symphony" />
        <meta name="description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />
        <meta name="keywords" content="Kuala Lumpur, Malaysia, Digital Symphony, Digital Agency, performance" />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
        <meta name="author" content="Digital Symphony" />
      </Helmet>
      <Navibar />
      <div className="wrapper about_wrapper">
        <div className="page-header about_header" style={{ flexWrap: 'wrap' }}>
          <canvas id="gradient-canvas"></canvas>
          <Container className="align-items-center">
            <Row>
              <Col md="12">
                <h1 className="header_title">Driven by passion,</h1>
                <h5 className="header_keywords">Led by data</h5>
              </Col>
            </Row>
          </Container>
        </div>

        <section className="section" style={{ backgroundColor: 'white' }}>
          <img src="/about/element.png" alt="" className="top_element" />
          <Container className="no_max_width">
            <Row>
              <Col md="12">
                <h1 className="text-center section_title ">
                  Bringing <b className="gradient_wording2">ideas to life</b>
                </h1>
                <p className="subtitle text-center" data-aos="fade-up">
                  We are a data driven, strategy led consultancy that believe in the
                  details
                </p>
              </Col>
              <Col
                className="info info-horizontal d-flex align-items-start hide_about"
                md="4"
                sm="4"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <img src="/about/details.gif" className="icon_image" alt="" />
                <div className="description">
                  <h4 className="info-title">Details</h4>
                  <p>
                    We believe the edge in digital activation is thought given
                    to the smallest details
                  </p>
                </div>
              </Col>
              <Col
                className="info info-horizontal d-flex align-items-start hide_about"
                md="4"
                sm="4"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <img src="/about/data.gif" className="icon_image" alt="" />
                <div className="description">
                  <h4 className="info-title">Data</h4>
                  <p>At the heart of operation is data that matters</p>
                </div>
              </Col>
              <Col
                className="info info-horizontal d-flex align-items-start hide_about"
                md="4"
                sm="4"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <img src="/about/strategy.gif" className="icon_image" alt="" />
                <div className="description">
                  <h4 className="info-title">Strategy</h4>
                  <p>
                    We believe data without strategy is just a set of numbers
                  </p>
                </div>
              </Col>
              <AboutUs />
            </Row>
          </Container>
        </section>

        <section className="section my_team_section">
          <Container>
            <Row>
              <Col md="12">
                <h1 className="section_title" data-aos="fade-right">
                  <b>The Team</b>
                </h1>
                <h4 className="subtitle" data-aos="fade-left">
                  We represent the vision of being a <br />
                  world class digital consultancy.
                </h4>
              </Col>
              <MyTeam />
            </Row>
          </Container>
        </section>

        <section
          className="section about_car_section"
          style={{ backgroundColor: 'white' }}
        >
          <Container>
            <Row>
              <Col md="12">
                <h1 className="text-center section_title" data-aos="fade-up">
                  <b>
                    Office <span className="gradient_wording2">Life</span>
                  </b>
                </h1>
                <p
                  className="subtitle text-center"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Our space where we show up to be the best versions of
                  ourselves. Sneak a peek of our daily lifes.
                </p>
              </Col>
            </Row>
          </Container>
          <OfficeOwl />
        </section>
        <div className="section breaker_section">
          <Container>
            <Row className="justify-content-between">
              {/* <img
                src="/about/element2.png"
                alt=""
                data-aos="fade-left"
                className="left_element"
              />
              <img
                src="/about/element2.png"
                alt=""
                data-aos="fade-right"
                className="right_element"
              /> */}
              <Col md="7">
                <h1
                  className="section_title text-left"
                  style={{ fontFamily: 'DM Serif Display' }}
                  data-aos="fade-up"
                >
                  Looking for a job to match your{' '}
                  <b style={{ fontFamily: 'DM Serif Display' }}>
                    ambitions?
                  </b>
                </h1>
                <p className="subtitle text-left" data-aos="fade-up">
                  We are always recruiting like minded problem solvers to join
                  our journey.
                </p>
                <Link
                  to="/career"
                  className="breaker_link"
                  data-aos="fade-right"
                >
                  VIEW CAREERS
                  <img
                    src="/home/arrow_white.svg"
                    alt="right white arrow"
                    style={{ marginLeft: '20px' }}
                  />
                </Link>
              </Col>
              <Col md="5">
                {/*<Row className="justify-content-between align-items-center">
                  <UncontrolledCarousel items={carouselItems} />
                </Row>*/}
                <img
                  src="/about/women.png"
                  data-aos="fade-left"
                  alt="girl"
                  className="girl_img"
                />
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}

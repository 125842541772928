import React from 'react';
import { Link } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';

export default function Navibar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [collapseOut, setCollapseOut] = React.useState('');
  const [color, setColor] = React.useState('navbar-transparent');
  React.useEffect(() => {
    Aos.init({ duration: 1500 });
    window.addEventListener('scroll', changeColor);
    return function cleanup() {
      window.removeEventListener('scroll', changeColor);
    };
  }, []);
  const changeColor = () => {
    if (
      document.documentElement.scrollTop > 99 ||
      document.body.scrollTop > 99
    ) {
      setColor('bg-info');
    } else if (
      document.documentElement.scrollTop < 100 ||
      document.body.scrollTop < 100
    ) {
      setColor('navbar-transparent');
    }
  };
  const toggleCollapse = () => {
    document.documentElement.classList.toggle('nav-open');
    setCollapseOpen(!collapseOpen);
  };
  const onCollapseExiting = () => {
    setCollapseOut('collapsing-out');
  };
  const onCollapseExited = () => {
    setCollapseOut('');
  };
  return (
    <Navbar className={'fixed-top ' + color} color-on-scroll="100">
      <Container fluid={true} className="px-5">
        <div className="navbar-translate">
          <NavbarBrand to="/" id="navbar-brand" tag={Link} data-aos="fade-down">
            <img
              className="logo_img normal_logo"
              src="/logo/logo.png"
              alt="white logo"
            />
            <img
              className="logo_img sticky_logo"
              src="/logo/ds-white.png"
              alt="white logo"
            />
          </NavbarBrand>
          <button
            aria-expanded={collapseOpen}
            className="navbar-toggler navbar-toggler"
            onClick={toggleCollapse}
            data-aos="fade-down"
            data-aos-delay="200"
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className={'justify-content-end ' + collapseOut}
          navbar
          isOpen={collapseOpen}
          onExiting={onCollapseExiting}
          onExited={onCollapseExited}
        >
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-close text-right" xs="12">
                <button
                  aria-expanded={collapseOpen}
                  className="navbar-toggler"
                  onClick={toggleCollapse}
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    size="2x"
                    className="close_btn"
                  />
                </button>
              </Col>
              <Col className="collapse-brand" md="8" sm="12">
                {/*<a href="#pablo" onClick={(e) => e.preventDefault()}>
                  BLK•React
                </a>*/}
                <div className="menu_link tra20 delay01 ">
                  <Link to="/about">About</Link>
                </div>
                <div className="menu_link tra20 delay02 ">
                  <Link to="/service">Services</Link>
                </div>
                <div className="menu_link tra20 delay03 ">
                  <Link to="/work">Work</Link>
                </div>
                <div className="menu_link tra20 delay04 ">
                  <Link to="/journal">Journal</Link>
                </div>
              </Col>
              <Col
                className="collapse-brand d-flex align-items-center flex-wrap px-5"
                md="4"
                sm="6"
                xs="11"
              >
                <div>
                  <Link to="/career" className="small_menu tra20 delay01">
                    Career
                  </Link>
                  {/* <Link to="/contact-us" className="small_menu tra20 delay02">
                  Contact us
                </Link> */}
                  <a href="/contact-us" className="small_menu tra20 delay02">
                    Contact us
                  </a>
                  <p className="text-uppercase tra20 delay03">New Business</p>
                  <p className="white_label tra20 delay04">Sean Lim (MY)</p>
                  <a
                    href="mailto:sean@digitalsymphony.it"
                    className="mail_link tra20 delay04 mb-3"
                    style={{ opacity: '0.85' }}
                  >
                    sean@digitalsymphony.it
                  </a>
                  {/* <p className="white_label tra20 delay04">Chrix Yong (SG)</p>
                  <a
                    href="mailto:chrix.yong@digitalsymphony.sg"
                    className="mail_link tra20 delay04"
                    style={{ opacity: '0.85' }}
                  >
                    chrix.yong@digitalsymphony.sg
                  </a> */}
                  <p className="text-uppercase tra20 delay05">Say Hello</p>
                  <a
                    href="mailto:barista@digitalsymphony.it"
                    className="mail_link tra20 delay05"
                  >
                    barista@digitalsymphony.it
                  </a>
                  <a
                    href="mailto:howdy@digitalsymphony.sg"
                    className="mail_link tra20 delay05"
                  >
                    howdy@digitalsymphony.sg
                  </a>
                  <a href="tel:60378596573" className="mail_link tra20 delay05">
                    +0378596573
                  </a>
                </div>
                <div
                  className="d-flex w-100 mt-5 mb-5"
                  style={{ maxWidth: '300px' }}
                >
                  <a
                    href="https://www.facebook.com/digitalsymp/"
                    className="social_link tra20 delay06"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="icon-facebook"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/digitalsymp/"
                    className="social_link tra20 delay07"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="icon-instagram"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/3926676/admin/"
                    className="social_link tra20 delay08"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="icon-linkedin"></i>
                  </a>
                </div>
              </Col>
            </Row>
          </div>
          <Nav navbar></Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}
